import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  LinearProgress,
  Stack,
  TablePagination,
  Typography,
  styled,
} from "@mui/material";
import { DataGridPro, GridFilterModel } from "@mui/x-data-grid-pro";
import {
  communityDropdownApi,
  getTemplates,
  LoadTemplateDetails,
  renameTemplate,
} from "../../apiCalls";
import { CommunityTypes, UIContext, UIState } from "../../providers/UIProvider";
import { dispatchError } from "../../common/fx";
import { useNavigate, useSearchParams } from "react-router-dom";
import ValidatedInput from "../../components/ValidatedInput";
import { uniqueId } from "lodash";

const TemplateSummary = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const templateIdParam = searchParams.get("templateId");

  const [filt, setFilt] = React.useState({
    items: [],
    quickFilterValues: [""],
  });

  interface TemplateState {
    community: string;
    optionTemplates: any[];
    planId: number;
    planNumber: string;
    projectId: number;
    projectNumber: string;
    templateName: string;
    templateOwner: false;
  }

  const [loadingOptions, setLoadingOptions] = useState(true);
  const [templates, setTemplates] = useState<TemplateState | any>({});
  const [state, dispatch] = useContext<UIState | any>(UIContext);
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(100);
  const [totalRows, setTotalRows] = useState(0);
  const [templateInfo, setTemplateInfo] = useState({
    templateName: "",
    community: "",
    planNumber: "",
    projectNumber: "",
  });

  const [communities, setCommunities] = useState<any[]>([]);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 0,
  });

  // Update the pagination model when the TablePagination changes
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    setPaginationModel((prev) => ({ ...prev, page: newPage }));
  };

  const handleChangePageSize = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(parseInt(event.target.value, 10));

    setPage(0); // Reset to the first page
    setPaginationModel((prev) => ({
      ...prev,
      pageSize: parseInt(event.target.value, 10),
      page: 0,
    }));
  };

  const handleUpdateTemplate = (objParam: {
    templateId: string;
    newName: string;
  }) => {
    renameTemplate(
      objParam,
      (res: any) => {
        setTemplateInfo({ ...templateInfo, templateName: objParam.newName });
        dispatch({
          type: "Snackbar",
          payload: {
            show: true,
            duration: 500,
            message: `Template name has been updated to ${objParam.newName}`,
            severity: "success",
          },
        });
      },
      (err: any) => dispatch(dispatchError(err.response.data))
    );
  };

  useEffect(() => {
    templateInfo.templateName &&
      dispatch({
        type: "Navbar",
        payload: {
          reactElement: (
            <Stack
              direction={"row"}
              spacing={2}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Typography variant="h6">
                {`${templateInfo?.community} (${templateInfo?.projectNumber}) - ${templateInfo?.planNumber}`}
              </Typography>

              <ValidatedInput
                styleOverride={{
                  fontSize: "1.25rem",
                  color: "white!important",
                  "& .MuiInputBase-input": {
                    color: "white!important",
                  },
                }}
                className="templateName"
                editTitle="Template Name"
                inputVariant="standard"
                mode="editor"
                onChange={(newValue) =>
                  handleUpdateTemplate({
                    templateId: templateIdParam ?? "0",
                    newName: newValue,
                  })
                }
                type="text"
                value={templateInfo.templateName}
                verify={false}
                saveTitle="Save this name"
              />
            </Stack>
          ),
        },
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateInfo]);

  useEffect(() => {
    if (templateIdParam) {
      setLoadingOptions(true);

      LoadTemplateDetails(
        { templateId: templateIdParam },
        (res: { data: TemplateState }) => {
          setTemplates(res.data ?? {});
          setTemplateInfo({
            community: res.data.community,
            templateName: res.data.templateName,
            planNumber: res.data.planNumber,
            projectNumber: res.data.projectNumber,
          });

          setLoadingOptions(false);
        },
        (err: any) => {
          dispatch(dispatchError(err));
          setLoadingOptions(false);
        }
      );
    } else {
      setLoadingOptions(false);
    }
  }, [templateIdParam]);

  const confirmColumns = [
    {
      field: "highlandCategory",
      headerName: "Option Category",
      flex: 1,
      maxWidth: 200,
    },
    {
      field: "category",
      headerName: "Category",
      width: 200,
    },

    {
      field: "optionCode",
      headerName: "Option Code",
      width: 150,
    },
    {
      field: "description",
      headerName: "Description",
      width: 400,
      flex: 1,
    },
    {
      field: "quantity",
      headerName: "Quantity",
      width: 100,
    },
  ];

  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1,
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <svg
          width="120"
          height="100"
          viewBox="0 0 184 152"
          aria-hidden
          focusable="false"
        >
          <g fill="none" fillRule="evenodd">
            <g transform="translate(24 31.67)">
              <ellipse
                className="ant-empty-img-5"
                cx="67.797"
                cy="106.89"
                rx="67.797"
                ry="12.668"
              />
              <path
                className="ant-empty-img-1"
                d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
              />
              <path
                className="ant-empty-img-2"
                d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
              />
              <path
                className="ant-empty-img-3"
                d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
              />
            </g>
            <path
              className="ant-empty-img-3"
              d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
            />
            <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
              <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
              <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
            </g>
          </g>
        </svg>
        <Box sx={{ mt: 1 }}>
          There are currently no saved option template to display.
        </Box>
      </StyledGridOverlay>
    );
  }

  return (
    <Container
      sx={{
        padding: 2,
      }}
      component="main"
      maxWidth={false}
    >
      <DataGridPro
        initialState={{
          sorting: {
            sortModel: [{ field: "changeOrderNumber", sort: "asc" }],
          },
        }}
        getRowId={(row: { id: string }) => row.id}
        density="compact"
        loading={loadingOptions}
        //@ts-ignore
        columns={confirmColumns}
        rows={templates?.optionTemplates ?? []}
        slots={{
          loadingOverlay: LinearProgress,
          noRowsOverlay: CustomNoRowsOverlay,
        }}
        filterModel={filt as GridFilterModel | undefined}
        onFilterModelChange={(newFilterModel) => {
          console.log(newFilterModel);
          setFilt(newFilterModel as any);
        }}
        sx={{
          minHeight: "40rem",
          "& .css-1iledgx-MuiFormControl-root": {
            marginBottom: "0px",
          },
          "& .MuiDataGrid-virtualScroller": {
            overflow: "visible!important",
          },
        }}
        pagination
      />

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={2}
        mt={2}
      >
        <Button
          variant="outlined"
          onClick={() => navigate("/templatemanagement")}
        >
          Back
        </Button>
        {/* <Box>
              <SubmitCancellationChangeOrder />
            </Box> */}
      </Stack>
    </Container>
  );
};

export default TemplateSummary;
