//import * as React from "react";
import { useContext } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
} from "@mui/material";
import {
  HighlandCategoryOption,
  OptionTypes,
  UIContext,
  UIState,
} from "../../providers/UIProvider";
import { PendingState } from "./interfaces";
import { ExpandMore, Delete, Assignment } from "@mui/icons-material";
import COViewGrid from "../../components/COViewGrid";

export default function Pending({ changeOrderId }: PendingState) {
  const [state, dispatch] = useContext<UIState | any>(UIContext);

  return (
    <Box
      className={`highland-category--wrapper ${
        state.pendingCategories.length === 0 ? "empty" : ""
      }`}
      width={"100%"}
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      {state.pendingCategories.length === 0 && (
        <>
          <div className="highland-category--empty-ux">
            <div className="highland-category--empty-icon-wrapper">
              <Assignment />
            </div>
            <Typography variant="h3">
              Change Order is currently empty
            </Typography>
            <Typography sx={{ fontSize: "1.5rem" }}>
              Select option category:
            </Typography>
          </div>
        </>
      )}

      {state.pendingCategories.length > 0 && (
        <Box
          sx={{
            minHeight: "calc(100vh - 424px)",
            width: "100%",
            mt: (theme) => theme.spacing(1),
          }}
        >
          {state.pendingCategories.map(
            (category: HighlandCategoryOption) => {
              const categoryItems: number =
                state.pendingItems.filter(
                  (item: OptionTypes) =>
                    item.highlandCategory.key === category.key && item.category
                ).length;
              return (
                <Accordion
                  className="highland-category--accordion"
                  defaultExpanded={true}
                  key={category.key}
                >
                  <AccordionSummary
                    className="highland-category--accordion--summary"
                    expandIcon={<ExpandMore />}
                    aria-controls={`hc-${category.key}`}
                    id={`hc-${category.key}`}
                  >
                    <Stack
                      direction="row"
                      sx={{ justifyContent: "space-between", width: "100%" }}
                    >
                      <Typography>
                        {category.label}
                        {categoryItems > 0 && <span> - {categoryItems}</span>}
                      </Typography>
                      {!state.pendingItems.some(
                        (item: OptionTypes) =>
                          item.highlandCategory.key === category.key
                      ) && (
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            dispatch({
                              type: "RemovePendingCategory",
                              payload: category,
                            });
                          }}
                          title="Remove this category"
                        >
                          <Delete />
                        </span>
                      )}
                    </Stack>
                  </AccordionSummary>
                  <AccordionDetails className="highland-category--accordion--details">
                    <COViewGrid category={category} />
                  </AccordionDetails>
                </Accordion>
              );
            }
          )}
        </Box>
      )}
    </Box>
  );
}
