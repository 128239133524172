import { useState, useContext, useEffect } from "react";
import { dispatchError } from "../../common/fx";
import ReusableDialog from "../../components/Dialog";
import {
  Autocomplete,
  Box,
  Button,
  DialogActions,
  DialogContent,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import {
  AddressTypes,
  BuyerTypes,
  CommunityTypes,
  ElevationTypes,
  OptionTypes,
  PlanTypes,
  UIContext,
  UIState,
} from "../../providers/UIProvider";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  changeOrderOptions,
  findCustomerByNameSF,
  getAddress,
  getPlanElevations,
  getPlans,
  communityDropdownApi,
  modelFromExistingIntention,
  modifyCOOptions,
  updateKeyword,
} from "../../apiCalls";
import {
  debounce,
  isNil,
  isNull,
  isEmpty,
  uniqBy,
  isObject,
  trim,
} from "lodash";
import useAccessControl from "../../hooks/useAccessControl";
import { filterOptions } from "../../utils/filterOptions";
type Props = {
  buttonTitle: string;
  createButtonTitle: string;
  modalTitle: string;
  intentionId: string;
  intentions?: any[];
};

const SummaryCreateChangeOrder = ({
  buttonTitle,
  createButtonTitle,
  modalTitle,
  intentionId,
  intentions,
}: Props) => {
  const [searchParams] = useSearchParams();

  let addressIdURL = searchParams.get("addressid");

  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [loadingBuyerName, setLoadingBuyerNames] = useState(false);
  const [modelIntentionLoading, setModelIntentionLoading] = useState(false);
  const communityDropdownApiAccess = useAccessControl(
    "Community",
    "GetActiveCommunities"
  );
  const FindCustomerByNameFromSFAccess = useAccessControl(
    "Customer",
    "FindCustomerByNameFromSF"
  );
  const GetPlansAccess = useAccessControl("Plan", "GetPlans");
  const GetPlanElevationsAccess = useAccessControl("Plan", "GetPlanElevations");
  const modelFromExistingIntentionAccess = useAccessControl(
    "Intention",
    "ModelFromExistingIntention"
  );
  const [state, dispatch] = useContext<UIState | any>(UIContext);
  useEffect(() => {
    if (state.selectedJob.projectId) {
      getAddress(
        { projectID: state.selectedJob.projectId },
        (res: any) => {
          dispatch({
            type: "AvailableAddresses",
            payload: res.data,
          });
        },
        (err: any) =>
          dispatch(
            dispatchError({
              message: err.message,
              statusText: err.response.statusText,
              title: err.response.data.title,
              status: err.response.status,
              detail: err.response.data.detail,
              data: err.response.data,
            })
          )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.selectedJob.projectId]);

  const sendToEdit = async () => {
    setModelIntentionLoading(true);
    function cleanObject(obj: Record<string, any>): Record<string, any> | null {
      const cleaned: Record<string, any> = {};
      for (const key in obj) {
        if (
          obj[key] === "" ||
          obj[key] === undefined ||
          (typeof obj[key] === "object" &&
            obj[key] !== null &&
            Object.keys(obj[key]).length === 0)
        ) {
          continue; // Skip empty strings, undefined values, and empty objects
        }

        if (typeof obj[key] === "object" && obj[key] !== null) {
          const cleanedSubObject = cleanObject(obj[key]);
          if (cleanedSubObject && Object.keys(cleanedSubObject).length > 0) {
            // Only add non-empty sub-objects
            cleaned[key] = cleanedSubObject;
          }
        } else {
          cleaned[key] = obj[key];
        }
      }
      return Object.keys(cleaned).length > 0 ? cleaned : null;
    }

    const { customerBuyer, keyword } = state.changeOrder;
    //always required fields:

    /*await findCustomerByNameSF(
      {
        BuyerName: state.selectedJob.buyerName,
        CommunityName: state.changeOrder.community.name
          ? state.changeOrder.community.name
          : "",
      },
      (res: any) => {*/
    modelFromExistingIntentionAccess &&
      modelFromExistingIntention(
        intentionId ?? addressIdURL,
        cleanObject({
          keyword: keyword,
          buyerFirstName: customerBuyer.buyerFirstName
            ? customerBuyer.buyerFirstName
            : customerBuyer,
          buyerLastName: customerBuyer.buyerLastName
            ? customerBuyer.buyerLastName
            : "_",
          buyerType: "buyer",
          salesforceAccountID: customerBuyer.salesforceAccountId
            ? customerBuyer.salesforceAccountId
            : customerBuyer.connectionId,
          salesforceConnectionNumber:
            //res.data[1]?.connectionNumber ??
            //res.data[0]?.connectionNumber ??
            customerBuyer.connectionNumber,
          buyerLegalName: customerBuyer.contractBuyerNames,
        }),
        (res: any) => {
          const intentionId = res.data;
          !isEmptyOrNull(keyword) &&
            updateKeyword(
              {
                intentionId: (res.data as string) || "",
                keyword: keyword,
              },
              (res: any) => {
                console.log(res);
              },
              (err: any) => dispatch(dispatchError(err.response.data))
            );

          if (intentions) {
            let gridIx = 0;
            let prevHC = "";
            const moPayload = intentions
              .sort((objA: any, objB: any) => {
                if (objA.highlandCategoryType < objB.highlandCategoryType)
                  return -1;
                if (objA.highlandCategoryType > objB.highlandCategoryType)
                  return 1;
                return 0;
              })
              .map((option: any, opIx: number) => {
                if (option.highlandCategoryType !== prevHC) {
                  prevHC = option.highlandCategoryType;
                  gridIx = 1;
                } else {
                  gridIx++;
                }
                return {
                  active: true,
                  category: option.category,
                  description: option.description,
                  forcedOption: false,
                  highlandCategory: option.highlandCategoryType,
                  isNew: true,
                  manualPrice: false,
                  notes: "", //option.notes,
                  option: option.category,
                  optionCategoryID: 1,
                  optionId: 1,
                  optionCode: option.optionCode,
                  quantity: -option.quantity,
                  unitPrice: option.unitPrice,
                  salesPrice: -option.salesPrice,
                  salesPriceControlID: option.salesPriceControlID,
                  sortOrder: gridIx,
                  cancelsOptionId: option.id,
                } as changeOrderOptions;
              });
            modifyCOOptions(
              res.data,
              { options: moPayload },
              (res: any) => {
                navigate(`/edit?intentionId=${intentionId}`);
                setModelIntentionLoading(false);
              },
              (err: any) =>
                dispatch(
                  dispatchError({
                    message: err.message,
                    statusText: err.response.statusText,
                    title: err.response.data.title,
                    status: err.response.status,
                    detail: err.response.data.detail,
                    data: err.response.data,
                  })
                )
            );
          } else {
            navigate(`/edit?intentionId=${intentionId}`);
            setModelIntentionLoading(false);
          }
        },
        (err: any) => {
          dispatch(dispatchError(err.response.data));
          setModelIntentionLoading(false);
        }
      );
    /*},
      (err: any) => {
        dispatch(dispatchError(err.response.data));
        setModelIntentionLoading(false);
      }
    );*/

    dispatch({
      type: "ResetChangeOrderPending",
    });

    //navigate(`/edit`);
  };

  const searchSalesForce = debounce((value: string) => {
    findCustomerByNameSF(
      {
        BuyerName: value,
        CommunityName: state.changeOrder.community.name
          ? state.changeOrder.community.name
          : "",
      },
      (res: any) => {
        dispatch({
          type: "AvailableBuyerNames",
          payload: res.data,
        });
        setLoadingBuyerNames(false);
      },
      (err: any) =>
        dispatch(
          dispatchError({
            message: err.message,
            statusText: err.response.statusText,
            title: err.response.data.title,
            status: err.response.status,
            detail: err.response.data.detail,
            data: err.response.data,
          })
        )
    );
  }, 1000);

  const fetchCommunities = () => {
    communityDropdownApi(
      (res: any) => {
        dispatch({
          type: "Communities",
          payload: res.data,
        });
      },
      (res: any) =>
        dispatch({
          type: "Snackbar",
          payload: {
            show: true,
            message: `${res.message} - ${res.response.statusText} -${res.response.data}`,
            severity: "error",
          },
        })
    );
  };

  useEffect(() => {
    dispatch({
      type: "AvailableBuyerNames",
      payload: [],
    });
    communityDropdownApiAccess && fetchCommunities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communityDropdownApiAccess]);

  const isEmptyOrNull = (value: any): boolean => {
    const trimedValue = trim(value);

    return trimedValue === "" || trimedValue === null;
  };

  return (
    <>
      <ReusableDialog
        setIsOpen={(value) => {
          setOpen(value);
        }}
        isOpen={open}
        maxWidth={"md"}
        content={
          <>
            <DialogContent>
              <>
                <Box marginY={(theme) => theme.spacing(2)}>
                  <Autocomplete
                    multiple={false}
                    fullWidth
                    size="medium"
                    disabled
                    value={state.changeOrder.community || null}
                    onChange={(e, value) => {
                      dispatch({
                        type: "ChangeOrder",
                        payload: {
                          community: value,
                          plan: "",
                          elevation: "",
                          buyer: "",
                          address: "",
                        },
                      });
                      if (value) {
                        getAddress(
                          { projectID: value.projectId },
                          (res: any) => {
                            dispatch({
                              type: "AvailableAddresses",
                              payload: res.data,
                            });
                          },
                          (err: any) =>
                            dispatch(
                              dispatchError({
                                message: err.message,
                                statusText: err.response.statusText,
                                title: err.response.data.title,
                                status: err.response.status,
                                detail: err.response.data.detail,
                                data: err.response.data,
                              })
                            )
                        );
                        GetPlansAccess &&
                          getPlans(
                            {
                              ProjectID: value.projectId,
                              ProjectNumber: value.projectNumber,
                            },
                            (res: any) => {
                              dispatch({
                                type: "AvailablePlans",
                                payload: res.data.sort(
                                  (
                                    a: {
                                      planNumber: string;
                                    },
                                    b: {
                                      planNumber: string;
                                    }
                                  ) => a.planNumber.localeCompare(b.planNumber)
                                ),
                              });
                            },
                            (err: any) =>
                              dispatch(
                                dispatchError({
                                  message: err.message,
                                  statusText: err.response.statusText,
                                  title: err.response.data.title,
                                  status: err.response.status,
                                  detail: err.response.data.detail,
                                  data: err.response.data,
                                })
                              )
                          );
                      }
                    }}
                    options={state.communities as CommunityTypes[]}
                    getOptionLabel={(option) =>
                      `${option.name} (${option.projectNumber})`
                    }
                    filterOptions={filterOptions}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Community"
                        fullWidth
                      />
                    )}
                  />
                </Box>
                <Box marginY={(theme) => theme.spacing(2)}>
                  <Autocomplete
                    multiple={false}
                    fullWidth
                    freeSolo
                    loading={loadingBuyerName}
                    size="medium"
                    defaultValue={""}
                    value={state?.changeOrder?.customerBuyer || null}
                    sx={{
                      ".Mui-error": {
                        color: "green!important",
                        borderColor: "green!important",
                      },
                      ".Mui-error fieldset": {
                        borderColor: "green!important",
                      },
                      ".Mui-error input": {
                        color: "black",
                      },
                    }}
                    onChange={(e, value) => {
                      dispatch({
                        type: "ChangeOrder",
                        payload: {
                          customerBuyer: value,
                          buyerType: "buyer",
                        },
                        source: "Summary CO buyer change",
                      });
                    }}
                    onInputChange={(e, value, reason) => {
                      if (reason !== "reset") {
                        if (value.toLowerCase() === "spec") {
                          dispatch({
                            type: "ChangeOrder",
                            payload: {
                              customerBuyer: {
                                salesforceAccountId: "Spec",
                                contractBuyerNames: "Spec",
                                connectionId: "Spec",
                                connectionNumber: "Spec",
                                buyerFirstName: "Spec",
                                buyerLastName: "",
                                customerBuyer: "Spec",
                                buyerType: "Spec",
                              },
                            },
                          });
                        } else if (!isNil(value) && value.length >= 3) {
                          dispatch({
                            type: "ChangeOrder",
                            payload: {
                              customerBuyer: value,
                              buyerType: "buyer",
                            },
                            source: "Summary CO buyer input change",
                          });
                          if (e && e?.type !== "click") {
                            setLoadingBuyerNames(true);
                            FindCustomerByNameFromSFAccess &&
                              searchSalesForce(value);
                          }
                        }
                      }
                    }}
                    options={(state.availableBuyerNames as BuyerTypes[]) || ""}
                    getOptionLabel={(option) => {
                      if (
                        option.connectionNumber === "Spec" ||
                        option.connectionNumber === "spec"
                      ) {
                        return `${option.contractBuyerNames}`;
                      }
                      if (option.buyerLegalName && option.connectionNumber) {
                        return `${option.buyerLegalName} - ${option.connectionNumber}`;
                      } else if (
                        option.contractBuyerNames &&
                        option.connectionNumber
                      ) {
                        return `${option.contractBuyerNames} - ${option.connectionNumber}`;
                      } else if (option.contractBuyerNames) {
                        return `${option.contractBuyerNames}`;
                      } else return option;
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        error={
                          isObject(state?.changeOrder?.customerBuyer) &&
                          state?.changeOrder?.customerBuyer?.connectionId
                        }
                        helperText={
                          <>
                            {isObject(
                              state?.createChangeOrderForm?.customerBuyer
                            ) &&
                              state?.createChangeOrderForm?.customerBuyer
                                ?.connectionNumber &&
                              state.createChangeOrderForm.customerBuyer
                                .buyerFirstName && <>Buyer in Salesforce</>}

                            {isObject(
                              state?.createChangeOrderForm?.customerBuyer
                            ) &&
                              state?.createChangeOrderForm?.customerBuyer
                                ?.connectionNumber &&
                              !state.createChangeOrderForm.customerBuyer
                                .buyerFirstName && (
                                <Typography
                                  variant="caption"
                                  sx={{
                                    color: (theme: Theme) =>
                                      theme.palette.error.main,
                                  }}
                                >
                                  First Name Is Missing In Salesforce
                                </Typography>
                              )}
                          </>
                        }
                        label="Buyer"
                        fullWidth
                      />
                    )}
                  />
                </Box>
                <Box gap={2} marginY={(theme) => theme.spacing(2)}>
                  {/* <Box padding={1} flex={1}>
                    <RadioGroup
                      sx={{
                        flexDirection: "row",
                      }}
                      aria-labelledby="adjust by"
                      value={state.changeOrder.addressType}
                      name="adjustBy"
                      onChange={(e, value) => {
                        if (value === "freeForm") {
                          dispatch({
                            type: "ChangeOrder",
                            payload: {
                              addressFreeform: "TBD / Preliminary",
                            },
                          });
                        }
                        dispatch({
                          type: "ChangeOrder",
                          payload: {
                            addressType: value,
                          },
                        });
                      }}
                    >
                      <FormControlLabel
                        disabled
                        value={"knownAddress"}
                        control={<Radio size="small" />}
                        label={"Address"}
                      />
                      <FormControlLabel
                        disabled
                        value={"lotBlock"}
                        control={<Radio size="small" />}
                        label={"Lot and Block"}
                      />
                      <FormControlLabel
                        disabled
                        value={"freeForm"}
                        control={<Radio size="small" />}
                        label={"Freeform"}
                      />
                    </RadioGroup>
                  </Box> */}
                  {state.changeOrder.addressType === "knownAddress" && (
                    <Autocomplete
                      disabled
                      multiple={false}
                      fullWidth
                      size="medium"
                      defaultValue={""}
                      value={state.changeOrder.address || null}
                      onChange={(e, value) => {
                        if (isNull(value)) {
                          dispatch({
                            type: "ChangeOrder",
                            payload: {
                              address: "",
                              plan: {
                                planNumber: "",
                                planElevationNumber: "",
                              },
                              elevation: {
                                planNumber: "",
                                planElevationNumber: "",
                                abbreviation: "",
                              },
                            },
                          });
                        } else if (value) {
                          GetPlansAccess &&
                            getPlans(
                              {
                                ProjectID:
                                  state.changeOrder.community.projectId,
                                projectNumber:
                                  state.changeOrder.community.projectNumber,
                              },
                              (res: any) => {
                                dispatch({
                                  type: "AvailablePlans",
                                  payload: res.data.sort(
                                    (
                                      a: {
                                        planNumber: string;
                                      },
                                      b: {
                                        planNumber: string;
                                      }
                                    ) =>
                                      a.planNumber.localeCompare(b.planNumber)
                                  ),
                                });
                                const {
                                  planNumber,
                                  planElevationNumber,
                                  abbreviation,
                                  description,
                                } = value;
                                dispatch({
                                  type: "ChangeOrder",
                                  payload: {
                                    lot: "",
                                    block: "",
                                    addressFreeform: "",
                                    address: value,
                                    plan: {
                                      planNumber,
                                      planElevationNumber,
                                      description,
                                    },
                                    elevation: {
                                      planNumber,
                                      planElevationNumber,
                                      abbreviation,
                                    },
                                  },
                                });
                              },
                              (err: any) =>
                                dispatch(
                                  dispatchError({
                                    message: err.message,
                                    statusText: err.response.statusText,
                                    title: err.response.data.title,
                                    status: err.response.status,
                                    detail: err.response.data.detail,
                                    data: err.response.data,
                                  })
                                )
                            );
                        }
                      }}
                      options={
                        (state.availableAddresses as AddressTypes[]) || ""
                      }
                      getOptionLabel={(option: any) => {
                        if (typeof option === "string") return option;
                        if (option.address === "TBD / Preliminary")
                          return option.address;
                        return option && option.address !== null
                          ? `${option.address}  ${
                              !isNull(option.planNumber) &&
                              !isNull(option.planElevationNumber) &&
                              !isEmpty(option.planNumber) &&
                              !isEmpty(option.planElevationNumber)
                                ? `(${option.planNumber}-${option.planElevationNumber})`
                                : ""
                            } | ${option.projectNumber}-${option.jobNumber}`
                          : "";
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Address"
                          fullWidth
                        />
                      )}
                    />
                  )}

                  {state.changeOrder.addressType === "lotBlock" && (
                    <>
                      <TextField
                        label="Lot"
                        value={state.changeOrder.lot}
                        disabled
                        onChange={(e: any) =>
                          dispatch({
                            type: "ChangeOrder",
                            payload: {
                              address: "",
                              addressFreeform: "",
                              lot: e.target.value,
                            },
                          })
                        }
                        variant="outlined"
                      />
                      <TextField
                        sx={{
                          marginX: 1,
                        }}
                        label="Block"
                        disabled
                        value={state.changeOrder.block}
                        onChange={(e: any) =>
                          dispatch({
                            type: "ChangeOrder",
                            payload: {
                              address: "",
                              addressFreeform: "",
                              block: e.target.value,
                            },
                          })
                        }
                        variant="outlined"
                      />
                    </>
                  )}

                  {state.changeOrder.addressType === "freeForm" && (
                    <>
                      <TextField
                        label="Free Form"
                        fullWidth
                        defaultValue={"TBD / Preliminary"}
                        disabled
                        value={state.changeOrder.addressFreeform}
                        onChange={(e: any) =>
                          dispatch({
                            type: "ChangeOrder",
                            payload: {
                              address: "",
                              lot: "",
                              block: "",
                              addressFreeform: e.target.value,
                            },
                          })
                        }
                        variant="outlined"
                      />
                    </>
                  )}
                </Box>

                <Box marginY={(theme) => theme.spacing(2)}>
                  <Autocomplete
                    defaultValue={""}
                    disabled
                    fullWidth
                    getOptionLabel={(option) => {
                      return `${option.planNumber}${
                        option.description ? ` - ${option.description}` : ""
                      }`;
                    }}
                    multiple={false}
                    onChange={(e, value) => {
                      dispatch({
                        type: "ChangeOrder",
                        payload: {
                          plan: value,
                        },
                      });
                      if (!isNull(value)) {
                        if (value.projectID) {
                          const {
                            projectID,
                            projectNumber,
                            planId,
                            planNumber,
                          } = value;
                          GetPlanElevationsAccess &&
                            getPlanElevations(
                              { projectID, projectNumber, planId, planNumber },
                              (res: any) => {
                                dispatch({
                                  type: "AvailableElevations",
                                  payload: res.data,
                                });
                              },
                              (err: any) =>
                                dispatch(
                                  dispatchError({
                                    message: err.message,
                                    statusText: err.response.statusText,
                                    title: err.response.data.title,
                                    status: err.response.status,
                                    detail: err.response.data.detail,
                                    data: err.response.data,
                                  })
                                )
                            );
                        }
                      }
                    }}
                    onInputChange={(e, value) => {
                      if (!isNil(e)) {
                        if (e.type !== "click") {
                          dispatch({
                            type: "ChangeOrder",
                            payload: {
                              planOverride: value,
                            },
                          });
                        }
                      }
                    }}
                    options={uniqBy(state.availablePlans, (obj: PlanTypes) => {
                      return obj.description;
                    })}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Plan"
                        fullWidth
                      />
                    )}
                    size="medium"
                    value={state.changeOrder.plan || null}
                  />
                </Box>
                <Box marginY={(theme) => theme.spacing(2)}>
                  <Autocomplete
                    multiple={false}
                    fullWidth
                    size="medium"
                    defaultValue={""}
                    disabled
                    value={state.changeOrder.elevation || null}
                    onInputChange={(e, value) => {
                      if (!isNil(e)) {
                        if (e.type !== "click") {
                          dispatch({
                            type: "ChangeOrder",
                            payload: {
                              planElevationOverride: value,
                            },
                          });
                        }
                      }
                    }}
                    onChange={(e, value) => {
                      if (isNil(value)) {
                        dispatch({
                          type: "ChangeOrder",
                          payload: {
                            elevation: {
                              planElevationNumber: "",
                            },
                          },
                        });
                      } else
                        dispatch({
                          type: "ChangeOrder",
                          payload: {
                            elevation: value,
                          },
                        });
                    }}
                    options={state.availableElevations as ElevationTypes[]}
                    getOptionLabel={(option) => `${option.planElevationNumber}`}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Elevation"
                        fullWidth
                      />
                    )}
                  />
                </Box>
                <Box marginY={(theme) => theme.spacing(2)}>
                  <TextField
                    fullWidth
                    onChange={(e) =>
                      dispatch({
                        type: "ChangeOrder",
                        payload: {
                          keyword: e.target.value,
                        },
                      })
                    }
                    value={state.changeOrder.keyword}
                    label="Keyword (25 Character Limit)"
                    variant="outlined"
                    placeholder="Keywords (25 Character Limit)"
                    inputProps={{
                      maxLength: 25,
                    }}
                    helperText={`${
                      state.changeOrder.keyword
                        ? state.changeOrder.keyword.length
                        : 0
                    }/25 Characters`}
                  />
                </Box>
              </>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  dispatch({
                    type: "ChangeOrder",
                    payload: {
                      customerBuyer: {
                        buyerFirstName: state?.selectedJob.buyerFirstName,
                        buyerLastName: state?.selectedJob.buyerLastName,
                        buyerLegalName:
                          state?.selectedJob.buyerLegalName.contractBuyerNames,
                        contractBuyerNames:
                          state?.selectedJob?.buyerLegalName
                            ?.contractBuyerNames ??
                          state?.selectedJob?.buyerName,
                        connectionId:
                          state?.selectedJob?.buyerSalesforceAccountId,
                        connectionNumber:
                          state?.selectedJob?.buyerSalesforceConnectionNumber,
                      },
                      keyword: state?.selectedJob?.keyword,
                    },
                  });

                  setOpen(false);
                }}
                variant="outlined"
                color="primary"
              >
                Cancel
              </Button>
              <Button
                onClick={() => sendToEdit()}
                disabled={
                  isEmptyOrNull(trim(state.changeOrder.customerBuyer)) ||
                  modelIntentionLoading ||
                  (state?.customerBuyer?.connectionNumber &&
                    isEmptyOrNull(state?.customerBuyer.buyerFirstName))
                }
                variant="contained"
                color="primary"
              >
                {createButtonTitle}
              </Button>
            </DialogActions>
          </>
        }
        buttonStyle={{ minWidth: "10rem" }}
        buttonText={buttonTitle}
        title={modalTitle}
      />
    </>
  );
};

export default SummaryCreateChangeOrder;
