import { useContext, useState, useEffect } from "react";
import { UIContext, UIState } from "../../providers/UIProvider";
import ReusableDialog from "../../components/Dialog";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  TextField,
} from "@mui/material";
import { AddComment, Message } from "@mui/icons-material";
import useAccessControl from "../../hooks/useAccessControl";

type Props = {
  note: {
    id: string;
    field: string;
    value: string;
  };
  setRows?: any;
  rows?: any;
  disabled?: boolean;
};

const Notes = ({ note, setRows, rows, disabled }: Props) => {
  const createNoteAccess = useAccessControl("Note", "CreateNote");

  const [state] = useContext<UIState | any>(UIContext);
  const [tempNote, setTempNote] = useState<string>(note.value);
  const [isEdited, setIsEdited] = useState<boolean>(false);

  useEffect(() => {
    setTempNote(note.value);
  }, [note]);

  useEffect(() => {
    setIsEdited(false);
  }, [state.changeOrder.lastSaved]);

  const handleConfirm = () => {
    setIsEdited(true);
    const updatedArray = rows.map((obj: any) => {
      if (obj.id === note.id) {
        return {
          ...obj,
          [note.field]: tempNote,
        };
      } else return obj;
    });

    setRows({
      type: "ChangeOrderPending",
      payload: updatedArray,
      source: "handleConfirm",
    });
  };
  const [open, setOpen] = useState(false);

  const renderNoteToolTip = () => {
    if (note.value) {
      return isEdited ? `${note.value} (unsaved)` : note.value;
    }
    return "Add a note";
  };

  return (
    <span title={renderNoteToolTip()}>
      <ReusableDialog
        setIsOpen={setOpen}
        isOpen={open}
        maxWidth={"md"}
        icon={
          note.value ? (
            <Message
              sx={{
                color: (theme) =>
                  isEdited ? "#e56d29" : theme.palette.primary.main,
              }}
            />
          ) : (
            //!disabled &&
            <AddComment sx={{ color: (theme) => theme.palette.primary.main }} />
          )
        }
        content={
          <>
            <DialogContent>
              <Box padding={(theme) => theme.spacing(2)}>
                <TextField
                  multiline
                  fullWidth
                  onChange={(e: any) => setTempNote(e.target.value)}
                  rows={6}
                  sx={{
                    mt: (theme) => theme.spacing(2),
                  }}
                  defaultValue={tempNote}
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={(e) => {
                  setTempNote(note.value);
                  setOpen(false);
                }}
                variant="outlined"
                color="primary"
              >
                Cancel
              </Button>
              {createNoteAccess && (
                <Button
                  onClick={() => {
                    handleConfirm();
                    setOpen(false);
                  }}
                  variant="contained"
                  color="primary"
                >
                  Save
                </Button>
              )}
            </DialogActions>
          </>
        }
        title="Change Order Note"
        type="icon"
      />
    </span>
  );
};

export default Notes;
