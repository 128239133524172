import React, { useContext, useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Container,
  IconButton,
  LinearProgress,
  ListItemText,
  Stack,
  TextField,
  Tooltip,
  styled,
} from "@mui/material";
import { Delete, Edit, Visibility } from "@mui/icons-material";
import {
  DataGridPro,
  GridFilterModel,
  GridRenderCellParams,
} from "@mui/x-data-grid-pro";
import { DeleteSystemMessage, GetAllSystemMessages } from "../../apiCalls";
import { UIContext, UIState } from "../../providers/UIProvider";
import { dispatchError } from "../../common/fx";
import { filterOptions } from "../../utils/filterOptions";
import DeleteConfirmation from "../../modals/DeleteConfirmation";
import moment from "moment";
import NotificationModal from "./notificationModal";
import EditNotificationModal from "./editNotificationModal";
import ViewNotificationModal from "./viewNotificationModal";

interface SystemMessage {
  createdBy: string;
  notificationType: string;
  expirationDate: string;
  systemMessage: string;
  id: string;
}
const Index = () => {
  const [filt, setFilt] = React.useState({
    items: [],
    quickFilterValues: [""],
  });
  const [loadingOptions, setLoadingOptions] = useState(true);
  const [notifications, setNotifications] = useState<SystemMessage[] | []>([]);

  const [state, dispatch] = useContext<UIState | any>(UIContext);
  // const [page, setPage] = React.useState(0);
  // const [pageSize, setPageSize] = React.useState(100);
  // const [totalRows, setTotalRows] = useState(0);
  const [editObject, setEditObject] = useState<{
    type: string;
    title: string;
    message: string;
    expiresAt: string;
    id: string | null;
  }>({
    type: "",
    title: "",
    message: "",
    expiresAt: "",
    id: null,
  });
  const [viewObject, setViewObject] = useState<{
    type: string;
    title: string;
    message: string;
    expiresAt: string;
    id: string | null;
  }>({
    type: "",
    title: "",
    message: "",
    expiresAt: "",
    id: null,
  });
  const [notificationType, setNotificationType] = useState<
    "KnownIssue" | "NewFeature" | "SystemMaintenance" | null
  >(null);
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [viewOpen, setViewOpen] = useState(false);

  // const [paginationModel, setPaginationModel] = React.useState({
  //   pageSize: 100,
  //   page: 0,
  // });
  const [confirmDelete, setConfirmDelete] = useState<{
    status: boolean;
    title: "";
    creatorName: "";
    expirationDate: "";
    message: "";
    id: "";
  }>({
    status: false,
    title: "",
    expirationDate: "",
    id: "",
    message: "",
    creatorName: "",
  });

  // Update the pagination model when the TablePagination changes
  // const handleChangePage = (event: unknown, newPage: number) => {
  //   setPage(newPage);
  //   setPaginationModel((prev) => ({ ...prev, page: newPage }));
  // };

  // const handleChangePageSize = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setPageSize(parseInt(event.target.value, 10));

  //   setPage(0); // Reset to the first page
  //   setPaginationModel((prev) => ({
  //     ...prev,
  //     pageSize: parseInt(event.target.value, 10),
  //     page: 0,
  //   }));
  // };

  const fetchSystemMessages = () => {
    GetAllSystemMessages(
      {
        messageType: notificationType ?? "",
        includeExpired: true,
      },

      (res: any) => {
        console.log(res.data);
        setNotifications(res.data);
      },
      (res: any) =>
        dispatch({
          type: "Snackbar",
          payload: {
            show: true,
            message: `${res.message} - ${res.response.statusText} -${res.response.data}`,
            severity: "error",
          },
        })
    );
  };

  const handleDeleteRow = (id: any) => {
    DeleteSystemMessage(
      id,
      (res: any) => {
        fetchSystemMessages();
        dispatch({
          type: "Snackbar",
          payload: {
            show: true,
            message: `System Message has been deleted.`,
            severity: "success",
          },
        });
      },
      (err: any) => dispatch(dispatchError(err.response.data))
    );
  };

  useEffect(() => {
    dispatch({
      type: "Navbar",
      payload: { title: `System Notifications` },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () => {
      setLoadingOptions(true);

      fetchSystemMessages();

      setLoadingOptions(false);
    },
    //@ts-ignore
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [notificationType]
  );

  useEffect(() => {
    editObject.id && setEditOpen(true);
  }, [editObject]);

  useEffect(() => {
    viewObject.id && setViewOpen(true);
  }, [viewObject]);

  const confirmColumns = [
    {
      field: "createdByName",
      headerName: "Created By",
      flex: 1,
      maxWidth: 450,
    },
    {
      field: "title",
      headerName: "Title",
      flex: 1,
      maxWidth: 450,
    },
    {
      field: "type",
      headerName: "Notification Type",
      flex: 1,
      width: 500,
      renderCell: (params: GridRenderCellParams) =>
        state.communities.find(
          (obj: { projectId: number }) => obj.projectId === params.row.projectId
        )?.name,
    },
    {
      field: "expiresAt",
      headerName: "Expiration Date",
      flex: 1,
      width: 400,
      valueFormatter: (params: GridRenderCellParams) => {
        return moment(params.value).format("MMMM D, h:mma");
      },
    },

    {
      field: "message",
      headerName: "System Message",
      width: 100,
      flex: 1,
    },

    {
      field: "actions",
      headerName: "",
      width: 100,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <>
          {moment(params.row.expiresAt).isAfter(moment()) ? (
            <Tooltip
              title={`Edit System Message Created For ${moment(
                params.row.expiresAt
              ).format("MMMM D, h:mma")}`}
            >
              <IconButton
                onClick={() =>
                  setEditObject({
                    ...params.row,
                    expiresAt: moment(params.row.expiresAt),
                  })
                }
              >
                <Edit />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip
              title={`View System Message Created For ${moment(
                params.row.expiresAt
              ).format("MMMM D, h:mma")}`}
            >
              <IconButton
                onClick={() =>
                  setViewObject({
                    ...params.row,
                    expiresAt: moment(params.row.expiresAt),
                  })
                }
              >
                {" "}
                <Visibility />
              </IconButton>
            </Tooltip>
          )}

          <Tooltip
            title={`Delete System Message Created For ${moment(
              params.row.expiresAt
            ).format("MMMM D, h:mma")}`}
          >
            <IconButton
              onClick={() =>
                setConfirmDelete({
                  status: true,
                  title: params?.row?.title,
                  creatorName: params?.row?.createdByName,
                  expirationDate: params?.row?.expiresAt,
                  message: params?.row?.systemMessage,
                  id: params?.row?.id,
                })
              }
            >
              <Delete />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];

  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1,
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <svg
          width="120"
          height="100"
          viewBox="0 0 184 152"
          aria-hidden
          focusable="false"
        >
          <g fill="none" fillRule="evenodd">
            <g transform="translate(24 31.67)">
              <ellipse
                className="ant-empty-img-5"
                cx="67.797"
                cy="106.89"
                rx="67.797"
                ry="12.668"
              />
              <path
                className="ant-empty-img-1"
                d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
              />
              <path
                className="ant-empty-img-2"
                d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
              />
              <path
                className="ant-empty-img-3"
                d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
              />
            </g>
            <path
              className="ant-empty-img-3"
              d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
            />
            <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
              <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
              <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
            </g>
          </g>
        </svg>
        <Box sx={{ mt: 1 }}>
          There are currently no system notification to display.
        </Box>
      </StyledGridOverlay>
    );
  }

  return (
    <Container component="main" maxWidth={false}>
      <Stack
        mt={2}
        mb={4}
        gap={2}
        sx={{
          alignItems: "center",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: { xs: "center", md: "space-between" },
        }}
      >
        <Box
          sx={{
            maxWidth: {
              xs: "100%",
              md: "40%",
            },
            flexDirection: { xs: "column", md: "row" },
            display: "flex",
          }}
          width={"100%"}
          gap={2}
          display={"flex"}
          alignItems={"center"}
        >
          <Autocomplete
            sx={{
              minWidth: "15rem",
            }}
            size="small"
            onChange={async (events, value) => {
              console.log(value);
              setNotificationType(
                value as "KnownIssue" | "NewFeature" | "SystemMaintenance"
              );
            }}
            options={["KnownIssue", "NewFeature", "SystemMaintenance"]}
            getOptionLabel={(option) => `${option}`}
            value={notificationType}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Notification Type"
              />
            )}
            filterOptions={filterOptions}
            renderOption={(props, item) => (
              <li {...props}>
                <ListItemText>{`${item}`}</ListItemText>
              </li>
            )}
          />
        </Box>

        <NotificationModal
          setOpen={(value: boolean) => setOpen(value)}
          open={open}
          fetchSystemMessages={fetchSystemMessages}
        />

        <EditNotificationModal
          fetchSystemMessages={fetchSystemMessages}
          editObject={editObject}
          setOpen={(value: boolean) => setEditOpen(value)}
          open={editOpen}
        />

        <ViewNotificationModal
          fetchSystemMessages={fetchSystemMessages}
          viewObject={viewObject}
          setOpen={(value: boolean) => setViewOpen(value)}
          open={viewOpen}
        />
      </Stack>
      <DeleteConfirmation
        setIsOpen={() =>
          setConfirmDelete({
            status: false,
            title: "",
            expirationDate: "",
            creatorName: "",
            id: "",
            message: "",
          })
        }
        deleteMessage={
          <p>
            Delete System Message: <strong>{confirmDelete.title} </strong>{" "}
            created by {confirmDelete.creatorName}?
          </p>
        }
        deleteTitle={"Delete System Message"}
        onClose={() =>
          setConfirmDelete({
            status: false,
            title: "",
            creatorName: "",
            expirationDate: "",
            id: "",
            message: "",
          })
        }
        isOpen={confirmDelete.status}
        confirmDelete={() => {
          setConfirmDelete({
            status: false,
            title: "",
            expirationDate: "",
            creatorName: "",
            id: "",
            message: "",
          });
          handleDeleteRow(confirmDelete.id);
        }}
      />
      <DataGridPro
        initialState={{
          sorting: {
            sortModel: [{ field: "changeOrderNumber", sort: "asc" }],
          },
        }}
        getRowId={(row) => row.id}
        density="standard"
        // rowCount={totalRows}
        loading={loadingOptions}
        //@ts-ignore
        columns={confirmColumns}
        rows={notifications}
        slots={{
          loadingOverlay: LinearProgress,
          noRowsOverlay: CustomNoRowsOverlay,
        }}
        filterModel={filt as GridFilterModel | undefined}
        onFilterModelChange={(newFilterModel) => {
          console.log(newFilterModel);
          setFilt(newFilterModel as any);
        }}
        sx={{
          minHeight: "40rem",
          "& .css-1iledgx-MuiFormControl-root": {
            marginBottom: "0px",
          },
          "& .MuiDataGrid-virtualScroller": {
            overflow: "visible!important",
          },
        }}
        pagination
      />
      {/* <TablePagination
        component="div"
        count={totalRows} // Total row count comes from the server
        page={page}
        nextIconButtonProps={{
          disabled: pageSize > templates.length ? true : false,
        }}
        onPageChange={handleChangePage}
        rowsPerPage={pageSize}
        onRowsPerPageChange={handleChangePageSize}
      /> */}
    </Container>
  );
};

export default Index;
