import { useState, useContext, useEffect } from "react";
import { dispatchError } from "../../common/fx";
import { Skeleton, Stack } from "@mui/material";
//import { Download } from "@mui/icons-material";
import { useSearchParams } from "react-router-dom";
import ValidatedInput from "../../components/ValidatedInput";
import {
  OptionTypes,
  UIContext,
  UIState,
  ConstructionManagerTypes,
} from "../../providers/UIProvider";
import {
  downloadQuotePDF,
  updateQuote
  //upsertConstructionManager
} from "../../apiCalls";
import useAccessControl from "../../hooks/useAccessControl";
import QuotePDFPreview from "../../modals/QuotePDFPreview";

export default function QuoteHeader(props:any) {
  const [searchParams] = useSearchParams();
  const [state, dispatch] = useContext<UIState | any>(UIContext);
  //let intentionId = searchParams.get("intentionId");
  const [isQuoteLoaded, setIsQuoteLoaded] = useState<boolean>(false);
  const [constructionManager, setConstructionManager] =
    useState<ConstructionManagerTypes | null>(null);
  const [downloading, setDownloading] = useState<boolean>(false);
  const DownloadQuoteAccess = useAccessControl("Quote", "QuoteSummary");

  useEffect(() => {
    if(state.quote.status !== "clear") {
      setIsQuoteLoaded(true);
    }
  }, [state.quote.status]);
  
  /*useEffect(() => {
    if (state.quote.cmName && state.constructionManagers.length > 0) {
      const currentCM = state.constructionManagers.find(
        (option: ConstructionManagerTypes) =>
          option.cmName === state.quote.cmName
      );
      if (currentCM) setConstructionManager(currentCM);
    } else setConstructionManager(null);
  }, [state.quote.cmName, state.constructionManagers]);*/

  const handleDownloadItems = () => {
    setDownloading(true);

    if (state.quote.id) {
      DownloadQuoteAccess &&
      downloadQuotePDF(
          { quoteId: state.quote.id },
          async (res: {
            fileData: "string";
            contentType: "string";
            documentName: "string";
          }) => {
            function base64ToBlob(
              base64: string,
              contentType: string = ""
            ): Blob {
              // Convert Base64 to a byte array
              const byteCharacters = atob(base64);
              const byteArrays = [];

              for (
                let offset = 0;
                offset < byteCharacters.length;
                offset += 512
              ) {
                const slice = byteCharacters.slice(offset, offset + 512);
                const byteNumbers = new Array(slice.length);

                for (let i = 0; i < slice.length; i++) {
                  byteNumbers[i] = slice.charCodeAt(i);
                }

                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
              }

              // Create a blob from the byte array
              return new Blob(byteArrays, { type: contentType });
            }

            function downloadPDF(
              documentName: string,
              contentType: string,
              fileData: string
            ) {
              // Convert Base64 fileData to Blob
              const blob = base64ToBlob(fileData, contentType);

              // Create a Blob URL
              const blobUrl = window.URL.createObjectURL(blob);

              // Create a link element
              const link = document.createElement("a");

              // Set the download attribute with a filename
              link.download = documentName;

              // Set the href to the blob URL
              link.href = blobUrl;

              // Append the link to the document body
              document.body.appendChild(link);

              // Programmatically click the link to trigger the download
              link.click();

              // Clean-up: remove the link from the document
              document.body.removeChild(link);
            }

            console.log('PDF data', res);
            downloadPDF(res.documentName, res.contentType, res.fileData);
            setDownloading(false);
          },
          (err: any) => {
            dispatch(
              dispatchError({
                message: err.message,
                statusText: err.response.statusText,
                title: err.response.data.title,
                status: err.response.status,
                detail: err.response.data.detail,
                data: err.response.data,
              })
            );
            setDownloading(false);
          }
        );
    }
  };

  return (
    <Stack
      alignItems={"center"}
      alignContent={"center"}
      gap={2}
      flexDirection={"row"}
      sx={{
        alignItems: "top",
        border: 1,
        borderColor: (mainTheme) => mainTheme.palette.grey[300],
        borderTop: 4,
        borderRadius: "10px",
        width: "100%",
        borderTopColor: (mainTheme) => mainTheme.palette.primary.main,
        justifyContent: { xs: "center", md: "space-between" },
        flexDirection: { xs: "column", md: "row" },
        maxWidth: { xs: "100%", lg: "70%" },
        py: 0.5,
        px: 2,
      }}
    >
      <div className="edit-summary-details">
        <p style={{ margin: "0 auto .5rem" }}>
          <strong>Buyer:</strong>
        </p>
        <div className="edit-summary-details--info">
          {isQuoteLoaded ? (
            <ValidatedInput
              className="edit-summary-details--editor"
              inputVariant="standard"
              isVerified={
                !!state.quote.quoteBuyer?.salesforceAccountId
              }
              mode="readonly"
              nonVerifiedTitle="This Quote doesn't has a buyer assigned yet"
              type="text"
              title={state.quote.quoteBuyer?.contractBuyerNames}
              value={`${state.quote.quoteBuyer.firstName} ${state.quote.quoteBuyer.lastName}`}
              verify={true}
              verifiedTitle="Buyer in SalesForce"
            />
          ) : (
            <Skeleton variant="text" height={24} width={160} />
          )}
        </div>
      </div>
      
      <div className="edit-summary-details">
        <p className="edit-summary-details--title">Community</p>
        <p className="edit-summary-details--info">
          {
            state.quote.address ?
            <ValidatedInput
              className="edit-summary-details--editor"
              inputVariant="outlined"
              isVerified={!(state.quote.quoteBuyer.salesforceConnectionNumber === "Create SF Connection")}
              mode="readonly"
              nonVerifiedTitle="this Quote doesn't have a SF Connection number yet"
              type="text"
              value={state.quote.address?.community}
              verify={true}
            />
            :
            <Skeleton variant="text" height={24} width={160} />
          }
        </p>
      </div>
      <div className="edit-summary-details">
        <p className="edit-summary-details--title">Address</p>
        <div className="edit-summary-details--info">
          {isQuoteLoaded ? (
            <ValidatedInput
              className="edit-summary-details--editor"
              dropdownData={state.availableAddresses}
              finalLabel={(value) => {
                if (value === null) return "";
                if (typeof value === "string") return value;
                return value.address;
              }}
              inputVariant="standard"
              isVerified={!!state.quote.address?.jobId}
              mode={state.quote.address.jobId ? "readonly" : "editor"}
              nonVerifiedTitle="this Quote doesn't have assigned an address from BRIX yet"
              onChange={(newAddress) => {
                updateQuote(
                  state.quote.id,
                  { jobId: newAddress?.jobID },
                  (response:any) => {
                    console.log(response);
                    dispatch({
                      type: "Quote",
                      payload: {
                        status: "clear"
                      },
                      source: "update Address on Quote Header"
                    })
                  },
                  (err:any) => {
                    dispatch(
                      dispatchError({
                        message: err.message,
                        statusText: err.response.statusText,
                        title: err.response.data.title,
                        status: err.response.status,
                        detail: err.response.data.detail,
                        data: err.response.data,
                      })
                    );
                  }
                )
              }}
              optionLabel={(option) => {
                if (typeof option === "string") return option;
                if (option.address === "TBD / Preliminary")
                  return option.address;
                return option && option.address !== null
                  ? `${option.address} ${
                      option.planNumber === null
                        ? ""
                        : `(${option.planNumber}-${option.planElevationNumber})`
                    }`
                  : "";
              }}
              saveTitle="Assign this Address to the Quote"
              type="autocomplete"
              title=""
              value={state.quote.address}
              verify={true}
              verifiedTitle="Address selected"
              width={250}
            />
          ) : (
            <Skeleton variant="text" height={24} width={160} />
          )}
        </div>
      </div>
      <div className="edit-summary-details">
        <p className="edit-summary-details--title">Plan / Elevation</p>
        <p className="edit-summary-details--info">
          {
            state.quote.plan?.planNumber ??
            <Skeleton variant="text" height={24} width={40} />
          }
          &nbsp;/&nbsp;
          {
            state.quote.plan?.planElevationNumber ??
            <Skeleton variant="text" height={24} width={60} />
          }
        </p>
      </div>
      { /*}
      <div className="edit-summary-details">
        <p className="edit-summary-details--title">Stage / CM</p>
        <div className="edit-summary-details--info">
          {isQuoteLoaded ? (
            <>
              {state.quote.stage === null
                ? ""
                : state.quote.stage}
            </>
          ) : (
            <Skeleton variant="text" height={24} width={40} />
          )}
          &nbsp;/&nbsp;
          {isQuoteLoaded && state.constructionManagers ? (
            <ValidatedInput
              className="edit-summary-details--editor"
              dropdownData={state.constructionManagers}
              //editTitle="Assign a Construction Manager"
              inputVariant="standard"
              isVerified={constructionManager !== null}
              mode="readonly"
              //nonVerifiedTitle="this Quote doesn't have assigned a Construction Manager yet"
              onChange={(newCM) => {
                upsertConstructionManager(
                  {
                    quoteId: state.quote.id || "",
                    CMName: newCM?.cmName,
                    SuperintendentID: newCM?.cmId,
                  },
                  (res: any) => {
                    dispatch({
                      type: "ChangeOrder",
                      payload: {
                        cmId: newCM?.cmId,
                        cmName: newCM?.cmName,
                        lastSaved: new Date(),
                      },
                      source: "upsertConstructionManager",
                    });
                  },
                  (err: any) => dispatch(dispatchError(err.response.data))
                );
              }}
              optionLabel={(option) => {
                if (typeof option === "string") return option;
                if (option === null) return "--";
                return option.cmName;
              }}
              type="autocomplete"
              value={constructionManager}
              verify={false}
              verifiedTitle="Construction Manager assigned"
              saveTitle="Assign this Construction Manager to the Quote"
            />
          ) : (
            <Skeleton variant="text" height={24} width={60} />
          )}
        </div>
      </div>
      */ 
      }
      <div className="edit-summary-details">
        <p style={{ margin: "0 auto .5rem"}}>
          <strong>SF Connection #</strong>
        </p>
        <div className="edit-summary-details--info">
          {isQuoteLoaded ? 
            <ValidatedInput
              className="edit-summary-details--editor"
              inputVariant="outlined"
              isVerified={!(state.quote.quoteBuyer.salesforceConnectionNumber === "Create SF Connection")}
              mode="readonly"
              nonVerifiedTitle="this Quote doesn't have a SF Connection number yet"
              type="text"
              value={state.quote.quoteBuyer.salesforceConnectionNumber}
              verify={true}
            />
             :
            <Skeleton variant="text" height={24} width={160} />
          }
        </div>
      </div>
      <div className="edit-summary-details">
        <p style={{ margin: "0 auto .5rem"}}>
          <strong>SF Stage</strong>
        </p>
        <div className="edit-summary-details--info">
          {isQuoteLoaded ? 
            state.quote.quoteBuyer.salesforceStage :
            <Skeleton variant="text" height={24} width={160} />
          }
        </div>
      </div>
      <Stack className="edit-summary-cta-wrapper" direction="row">
        { isQuoteLoaded ?
          <>
            <QuotePDFPreview
              quoteId={state.quote.id}
              disabled={
                !DownloadQuoteAccess ||
                downloading ||
                state.quote.quoteStatus === "Open" ||
                state.pendingItems.filter(
                  (item: OptionTypes) => !item.isNew
                ).length === 0
              }
              printFlow={handleDownloadItems}
              title="Print Preview"
            />
          </>
          :
          <>
            <Skeleton variant="rounded" height={40} width={40} />
          </>
        }
      </Stack>
    </Stack>
  )

}