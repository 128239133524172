import { useState, useContext, useEffect } from "react";
import { UIState, UIContext, OptionTypes } from "../../providers/UIProvider";
import { numberWithCommas } from "../../utils/formatMoney";
import { Button, DialogContent, DialogActions } from "@mui/material";
import { Add, PriceChange, Remove } from "@mui/icons-material";
import ReusableDialog from "../../components/Dialog";
import "./OptionPriceUpdates.scss";

interface OptionPriceUpdatesProps {
  onClose?: any;
};

const opuColumns = [
  { key: 'expander', label: '', class: 'center' },
  { key: 'hhcategory', label: 'Highland Category' },
  { key: 'option', label: 'Option' },
  { key: 'optionCode', label: 'Option Code' },
  { key: 'description', label: 'Description' },
  { key: 'previousPrice', label: 'Previous', class: 'right' },
  { key: 'updatedPrice', label: 'Updated', class: 'right' },
  { key: 'difference', label: 'Difference', class: 'right' },
  { key: 'updatedAt', label: 'Date updated', class: 'right' }
];

const OptionPriceUpdates = (props:OptionPriceUpdatesProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [state] = useContext<UIState | any>(UIContext);
  const [totalDiff, setTotalDiff] = useState<number>(0);
  const [openBlocks, setOpenBlocks] = useState<string[]>([]);

  useEffect(() => {
    let newTotalDiff = 0;
    state.pendingItems.forEach((option: OptionTypes) => {
      if(option.priceHistory) {
        option.priceHistory.forEach((priceChange: any) => {
          newTotalDiff += priceChange.difference;
        });
      }
    });
    if(newTotalDiff !== totalDiff) {
      setTotalDiff(newTotalDiff);
    }
  }, [state.pendingItems]);

  const handleAddBlock = (optionId:string) => {
    if(openBlocks.includes(optionId)) return false;
    const newOpenBlocks = [...openBlocks];
    newOpenBlocks.push(optionId);
    setOpenBlocks(newOpenBlocks);
  }

  const handleRemoveBlock = (optionId:string) => {
    if(openBlocks.includes(optionId)) {
      const optionIx = openBlocks.indexOf(optionId);
      const newOpenBlocks = [...openBlocks];
      newOpenBlocks.splice(optionIx, 1);
      setOpenBlocks(newOpenBlocks);
    }
  }
  
  const displayTable = () => {
    const rows:any[] = [];

    state.pendingItems.forEach((option: OptionTypes, ) => {
      if(option.priceHistory) {
        if(option.priceHistory.length === 1) {
          rows.push(
            <tr className="single">
              { opuColumns.map((column: any) => 
                  displayCell(option, option.priceHistory && option.priceHistory[0], column, "single")
              )}
            </tr>
          )
        } else {
          option.priceHistory.sort((a:any, b:any) => {
            if (a.updatedAt < b.updatedAt) return -1;
            if (a.updatedAt > b.updatedAt) return 1;
            return 0;
          });
          const parentRow = option.priceHistory[option.priceHistory.length - 1];
          /*{

            difference: option.priceHistory[option.priceHistory.length - 1].updatedPrice - option.priceHistory[0].previousPrice,
            previousPrice: option.priceHistory[0].previousPrice,
            updatedPrice: option.priceHistory[option.priceHistory.length - 1].updatedPrice,
            updatedAt: option.priceHistory[option.priceHistory.length - 1].updatedAt
          }*/
          rows.push(
            <tr className="parent">
              { opuColumns.map((column: any) => 
                  displayCell(option, parentRow, column, "parent")
              )}
            </tr>
          )
          if(openBlocks.includes(option.id)) {
            rows.push(
              option.priceHistory
                .sort((a:any, b:any) => {
                  if (a.updatedAt > b.updatedAt) return -1;
                  if (a.updatedAt < b.updatedAt) return 1;
                  return 0;
                })
                .map((updateRow:any, rowIx:number) => {
                  return (
                    <tr
                      className={`child${option.priceHistory && rowIx === option.priceHistory.length - 1 ? ' last' : ''}`}
                      key={`updated-price-${option.id}-${rowIx + 1}`}
                    >
                      { opuColumns.map((column: any) => 
                          displayCell(option, updateRow, column, "child")
                      )}
                    </tr>
                  )
                })
            );
          }
        }
      }
    });
    return rows;
  }

  const displayCell = (option:any, update:any, column:any, type: "parent" | "child" | "single") => {
    console.log()
    let cellValue;
    switch(column.key) {
      case 'expander':
        if(type === "parent")
          if(openBlocks.includes(option.id)) {
            cellValue = <div className="parent-toggle" onClick={() => handleRemoveBlock(option.id)}>
                          <Remove />
                        </div>;
          } else {
            cellValue = <div className="parent-toggle" onClick={() => handleAddBlock(option.id)}>
                          <Add />
                        </div>;
          }
        else if(type === "child")
          cellValue = <span className="child-branch"></span>
        else
          cellValue = '';
        break;
      case 'hhcategory':
        cellValue = type !== "child" ? option.highlandCategory.label : '';
        break;
      case 'option':
      case 'optionCode':
      case 'description':
        cellValue = type !== "child" ? option[column.key] : '';
        break;
      case 'previousPrice':
      case 'updatedPrice':
      case 'difference':
        cellValue = `$ ${numberWithCommas(update[column.key])}`;
        break;
      case 'updatedAt':
        const updatedDate = new Date(update['updatedAt']);
        const month = String(updatedDate.getMonth() + 1).padStart(2, '0');
        const day = String(updatedDate.getDate()).padStart(2, '0');
        const year = updatedDate.getFullYear();
        cellValue = `${month}/${day}/${year}`;
        break;
      default:
        cellValue = option[column.key];
    }
    return (
      <td id={`${option.id}-${column.key}`} className={`${column.class ?? ''}`}>
        {cellValue}
      </td>
    );
  }

  const handleClose = () => {
    setIsOpen(false);
    if(props.onClose) props.onClose();
  }

  return (
    <ReusableDialog
      buttonFullWidth={false}
      content={
        <>
          <DialogContent>
            <p style={{ color: "#e56d29" }}>
              Option(s) in this Change Order may have updated prices.
            </p>
            <table className="price-updates--table">
              <thead>
                <tr>
                  { opuColumns.map((column:any) => 
                    <th id={`th-${column.key}`} key={`th-${column.key}`}>
                      {column.label}
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                { displayTable() }
              </tbody>
            </table>
            <div style={{ marginTop: '16px', textAlign: 'right' }}>
              Total price difference: <strong>$ {numberWithCommas(totalDiff)}</strong>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
                onClick={() => {
                  handleClose();
                }}
                variant="outlined"
                color="primary"
              >
                Exit
            </Button>
          </DialogActions>
        </>
        
      }
      icon={<PriceChange sx={{ color: "#e56d29", fontSize: "1.25rem" }} />}
      isOpen={isOpen}
      maxWidth="lg"
      setIsOpen={(value) => setIsOpen(value)}
      title="Option Price Update History"
      type="icon"
    />
  );
}

export default OptionPriceUpdates;