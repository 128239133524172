//import * as React from "react";
import { useContext } from "react";
import useAccessControl from "../../hooks/useAccessControl";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
} from "@mui/material";
import {
  HighlandCategoryOption,
  OptionTypes,
  UIContext,
  UIState,
} from "../../providers/UIProvider";
import { PendingState } from "../../common/interfaces";
import { ExpandMore, Delete, Assignment, ReportProblem, RequestQuote } from "@mui/icons-material";
import COEditGrid from "../COEditGrid";
import COCategoryButton from "../COCategoryButton";
import LoadTemplate from "../../modals/LoadTemplate";
import COViewGrid from "../COViewGrid";

export interface OptionCategoriesProps {
  mode: "quote" | "edit" | "view"
};

export default function OptionCategories(props:OptionCategoriesProps) {
  const [state, dispatch] = useContext<UIState | any>(UIContext);
  const MeasuredOptionsAllowed = useAccessControl(
    "Option",
    "UseMeasuredOptions"
  );
  const templatesAllowed = useAccessControl("OptionTemplate", "GetTemplates");

  return (
    <Box
      className={`highland-category--wrapper ${
        state.pendingCategories.length === 0 ? "empty" : ""
      }`}
      width={"100%"}
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      {state.pendingCategories.length === 0 && (
        <>
          <div className="highland-category--empty-ux">
            <div className="highland-category--empty-icon-wrapper">
              { props.mode === "quote" && <RequestQuote /> }
              { props.mode === "edit" && <Assignment /> }
            </div>
            <Typography variant="h4">
              { props.mode === "edit" && `Change Order ` }
              { props.mode === "quote" && `Quote `}
              is currently empty
            </Typography>
            <Typography sx={{ fontSize: "1.5rem" }}>
              Select option category:
            </Typography>
          </div>
        </>
      )}
      <Stack
        direction="row"
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
        mt={1}
      >
        {state.highlandCategories
          .filter((category: HighlandCategoryOption) =>
            category.key === "MeasuredOption" ? MeasuredOptionsAllowed : true
          )
          .map((category: HighlandCategoryOption) => (
            <COCategoryButton category={category} key={`cb-${category.key}`} />
          ))}
      </Stack>

      {state.pendingCategories.length === 0 && templatesAllowed && (
        <Stack
          sx={{
            textAlign: "center",
          }}
        >
          <hr
            style={{
              border: "none",
              borderTop: "1px solid #1976d2",
              height: "0",
              margin: "40px auto 30px",
              width: "50%",
            }}
          />
          <p>
            <LoadTemplate />
          </p>
        </Stack>
      )}
      {state.pendingCategories.length > 0 && (
        <Box
          sx={{
            minHeight: "calc(100vh - 424px)",
            width: "100%",
            mt: (theme) => theme.spacing(1),
          }}
        >
          {state.pendingCategories
            .filter((category: HighlandCategoryOption) =>
              category.key === "MeasuredOption" ? MeasuredOptionsAllowed : true
            )
            .map((category: HighlandCategoryOption) => {
              const categoryItems: number =
                state.pendingItems.filter(
                  (item: OptionTypes) =>
                    item.highlandCategory.key === category.key && item.category
                ).length;
              return (
                <Accordion
                  className="highland-category--accordion"
                  defaultExpanded={true}
                  key={category.key}
                >
                  <AccordionSummary
                    className="highland-category--accordion--summary"
                    expandIcon={<ExpandMore />}
                    aria-controls={`hc-${category.key}`}
                    id={`hc-${category.key}`}
                  >
                    <Stack
                      direction="row"
                      sx={{ justifyContent: "space-between", width: "100%" }}
                    >
                      <Typography>
                        {category.label}
                        {categoryItems > 0 && <span> - {categoryItems}</span>}
                      </Typography>
                      {state.pendingItems.some(
                        (item: OptionTypes) =>
                          item.highlandCategory.key === category.key &&
                          (!item.category ||
                            item.category === "" ||
                            !item.description ||
                            item.description === "")
                      ) && <ReportProblem sx={{ marginRight: "8px" }} />}
                      {!state.pendingItems.some(
                        (item: OptionTypes) =>
                          item.highlandCategory.key === category.key
                      ) && (
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            dispatch({
                              type: "RemovePendingCategory",
                              payload: category,
                            });
                          }}
                          title="Remove this category"
                        >
                          <Delete />
                        </span>
                      )}
                    </Stack>
                  </AccordionSummary>
                  <AccordionDetails className="highland-category--accordion--details">
                    { (props.mode === "edit" || props.mode === "quote") && <COEditGrid category={category} mode={props.mode} /> }
                    { props.mode === "view" && <COViewGrid category={category} /> }
                  </AccordionDetails>
                </Accordion>
              );
            })}
        </Box>
      )}
    </Box>
  );
}
