import { useState, useContext, useEffect } from "react";
import ReusableDialog from "../../components/Dialog";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Stack,
  TextField,
  MenuItem,
  Grid2 as Grid,
  Typography,
} from "@mui/material";
import { Paragliding } from "@mui/icons-material";
import { UIContext, UIState } from "../../providers/UIProvider";
import { LoadingButton } from "@mui/lab";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import moment from "moment";
import {
  createNewStartMemo,
  getStartMemo,
  updateStartMemo,
} from "../../apiCalls";
import { useSearchParams } from "react-router-dom";
import { dispatchError } from "../../common/fx";
import useAccessControl from "../../hooks/useAccessControl";

const validationSchema = Yup.object().shape({
  jobType: Yup.string().required("Required"),
  swing: Yup.string().required("Required"),
});

const CreateStartMemo = ({
  disabled = false,
  title,
  startMemoId,
  buttonLabel,
}: {
  disabled?: boolean;
  title: string;
  buttonLabel: string;
  startMemoId?: string;
}) => {
  const createStartMemoAccess = useAccessControl("Job", "CreateStartMemo");

  const updateStartMemoAccess = useAccessControl("Job", "UpdateStartMemo");

  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [error, setError] = useState(false);
  const [state, dispatch] = useContext<UIState | any>(UIContext);
  const [open, setOpen] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<any>(null); // State for dynamic initial values

  const [searchParams] = useSearchParams();
  let intentionId = searchParams.get("intentionId");

  const defaultValues = {
    salesCounselor: "Need Data",
    cornerLot: "",
    waterInjection: "",
    piers: "",
    transferSpecial: "",
    moistureConditioning: "",
    moistureConditioningFt: "",
    backsToMainStreet: "",
    constructionManager: "Need Data",
    divisionSalesManager: "Need Data",
    divisionConstructionManager: "Need Data",
    date: state.changeOrder.updatedAt ?? state.changeOrder.createdAt,
    jobKey: `${state.changeOrder.address.projectNumber}-${state.changeOrder.address.jobNumber}`,
    subdivisionName: state.changeOrder.address.community,
    city: "Prosper",
    buyer:
      state.changeOrder.customerBuyer.contractBuyerNames ??
      `${state.changeOrder.customerBuyer.buyerFirstName} ${state.changeOrder.customerBuyer.buyerLastName}`,
    jobType: "Spec",
    swing: "Left",
    foundationLot: "Design",
    foundationDesign: "",
    additionalNotes: "",
    jobStatus: "New",
    droppedGarage: "No",
    special: "No",
    reasonForSpecial: "",
    inches: "5",
    brick1: "5",
    brick2: "5",
  };

  const handleFormSubmit = async (values: any) => {
    setLoadingSubmit(true);
    try {
      console.log("startMemoId", startMemoId);

      startMemoId === undefined || !startMemoId
        ? createNewStartMemo(
            {
              intentionId: intentionId as string,
              jobId: state.changeOrder.address.jobID,
              startMemoPayload: values,
            },
            (res: any) => {
              const payload = {
                ...state.changeOrder,
                startMemoId: res.data,
              };
              dispatch({
                type: "ChangeOrder",
                payload,
                source: "edit startMemoId state",
              });
            },
            (err: any) => console.log(err)
          )
        : updateStartMemo(
            {
              startMemoId: startMemoId as string,
              ...values,
            },
            () => {
              setFormValues(values);
            },
            (err: any) => dispatch(dispatchError(err.response.data))
          );

      setLoadingSubmit(false);
      setOpen(false);
    } catch (err) {
      setError(true);
      setLoadingSubmit(false);
    }
  };

  useEffect(() => {
    if (startMemoId !== undefined) {
      getStartMemo(
        startMemoId as string,
        (res: any) => {
          if (res) {
            setFormValues({ ...defaultValues, ...res });
          }
          if (window.location.hash === "#startmemo") {
            setOpen(true);
          }
        },
        (err: any) => {
          dispatch(dispatchError(err.response.data));
          if (window.location.hash === "#startmemo") {
            setOpen(true);
          }
        }
      );
    } else {
      setFormValues(defaultValues);
    }
  }, [startMemoId]);

  return (
    <>
      <ReusableDialog
        setIsOpen={setOpen}
        isOpen={open}
        disabled={disabled}
        buttonVariant={"contained"}
        buttonText={buttonLabel}
        maxWidth="lg"
        title={title}
        content={
          <Formik
            initialValues={formValues}
            validationSchema={validationSchema}
            onSubmit={handleFormSubmit}
            enableReinitialize
          >
            {({ values, errors, touched, handleChange, handleBlur }) => (
              <Form>
                <DialogContent>
                  <Box p={2}>
                    <Grid container spacing={2}>
                      <Grid size={{ xs: 12, sm: 6 }} spacing={2}>
                        <Grid size={{ xs: 12, sm: 3 }}>
                          <Typography variant="body1">
                            <b>Sales Counselor:</b> {values?.salesCounselor}
                          </Typography>
                        </Grid>
                        <Grid size={{ xs: 12, sm: 3 }}>
                          <Typography variant="body1">
                            <b>Construction Manager:</b>
                            {values?.constructionManager}
                          </Typography>
                        </Grid>
                        <Grid size={{ xs: 12, sm: 3 }}>
                          <Typography variant="body1">
                            <b>Division Sales Manager:</b>
                            {values?.divisionSalesManager}
                          </Typography>
                        </Grid>
                        <Grid size={{ xs: 12, sm: 3 }}>
                          <Typography variant="body1">
                            <b>Division Construction Manager:</b>
                            {values?.divisionConstructionManager}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid size={{ xs: 12, sm: 6 }} spacing={2}>
                        <Grid size={{ xs: 12, sm: 3 }}>
                          <Typography variant="body1">
                            <b>Date:</b>{" "}
                            {moment(values?.date).format("MM-DD-YYYY")}
                          </Typography>
                        </Grid>

                        <Grid size={{ xs: 12, sm: 3 }}>
                          <Typography variant="body1">
                            <b>Buyer:</b> {values?.buyer}
                          </Typography>
                        </Grid>
                        <Grid size={{ xs: 12, sm: 3 }}>
                          <Typography variant="body1">
                            <b>Subdivision Name:</b> {values?.subdivisionName}
                          </Typography>
                        </Grid>
                        <Grid size={{ xs: 12, sm: 3 }}>
                          <Typography variant="body1">
                            <b>City:</b> {values?.city}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid container spacing={2} sx={{ mt: 2 }}>
                      <Grid size={{ xs: 12, sm: 4 }}>
                        <TextField
                          name="jobType"
                          label="Job Type"
                          fullWidth
                          select
                          value={values?.jobType}
                          onChange={(e) => {
                            console.log(
                              "Change event:",
                              e.target.name,
                              e.target.value
                            );
                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                          error={touched.jobType && Boolean(errors.jobType)}
                          helperText={
                            touched.jobType && (errors.jobType as any)
                          }
                        >
                          <MenuItem value="P&H">P&H</MenuItem>
                          <MenuItem value="Spec">Spec</MenuItem>
                          <MenuItem value="Model">Model</MenuItem>
                          <MenuItem value="Prototype">Prototype</MenuItem>
                          <MenuItem value="Sold">Sold</MenuItem>
                        </TextField>
                      </Grid>
                      <Grid size={{ xs: 12, sm: 4 }}>
                        <TextField
                          name="jobStatus"
                          label="Job Status"
                          select
                          fullWidth
                          value={values?.jobStatus}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          {values?.jobType === "P&H" && [
                            <MenuItem key="p&h" value="P&H">
                              P&H
                            </MenuItem>,
                            <MenuItem key="put_on_p&h" value="Put on P&H">
                              Put on P&H
                            </MenuItem>,
                            <MenuItem key="new" value="New">
                              New
                            </MenuItem>,
                            <MenuItem key="revised" value="Revised">
                              Revised
                            </MenuItem>,
                            <MenuItem key="released" value="Released">
                              Released
                            </MenuItem>,
                          ]}

                          {values?.jobType === "Spec" && [
                            <MenuItem key="new" value="New">
                              New
                            </MenuItem>,
                            <MenuItem key="revised" value="Revised">
                              Revised
                            </MenuItem>,
                          ]}

                          {values?.jobType === "Model" && [
                            <MenuItem key="p&h" value="P&H">
                              P&H
                            </MenuItem>,
                            <MenuItem key="released" value="Released">
                              Released
                            </MenuItem>,
                            <MenuItem key="new" value="New">
                              New
                            </MenuItem>,
                            <MenuItem key="revised" value="Revised">
                              Revised
                            </MenuItem>,
                          ]}

                          {values?.jobType === "Prototype" && [
                            <MenuItem key="p&h" value="P&H">
                              P&H
                            </MenuItem>,
                            <MenuItem key="released" value="Released">
                              Released
                            </MenuItem>,
                            <MenuItem key="new" value="New">
                              New
                            </MenuItem>,
                            <MenuItem key="revised" value="Revised">
                              Revised
                            </MenuItem>,
                          ]}

                          {values?.jobType === "Sold" && [
                            <MenuItem key="new" value="New">
                              New
                            </MenuItem>,
                            <MenuItem key="revised" value="Revised">
                              Revised
                            </MenuItem>,
                            <MenuItem key="released" value="Released">
                              Released
                            </MenuItem>,
                          ]}
                        </TextField>
                      </Grid>
                      <Grid size={{ xs: 12, sm: 4 }}>
                        <TextField
                          name="foundationLot"
                          label="Foundation/Lot"
                          fullWidth
                          select
                          value={values?.foundationLot}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <MenuItem value="Design">Design</MenuItem>
                          <MenuItem value="Lot1">Lot 1</MenuItem>
                        </TextField>
                      </Grid>
                      <Grid size={{ xs: 12, sm: 4 }}>
                        <TextField
                          name="swing"
                          label="Swing"
                          fullWidth
                          select
                          value={values?.swing}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.swing && Boolean(errors.swing)}
                          helperText={touched.swing && (errors.swing as any)}
                        >
                          <MenuItem value="Left">Left</MenuItem>
                          <MenuItem value="Right">Right</MenuItem>
                        </TextField>
                      </Grid>

                      <Grid size={{ xs: 12, sm: 4 }}>
                        <TextField
                          name="droppedGarage"
                          label="Dropped Garage"
                          select
                          fullWidth
                          value={values?.droppedGarage}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <MenuItem value="No">No</MenuItem>
                          <MenuItem value="Yes">Yes</MenuItem>
                        </TextField>
                      </Grid>
                      {values?.droppedGarage === "Yes" && (
                        <Grid size={{ xs: 12, sm: 4 }}>
                          <TextField
                            name="inches"
                            label="Inches"
                            fullWidth
                            value={values?.inches}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Grid>
                      )}
                      <Grid size={{ xs: 12 }}>
                        <TextField
                          name="foundationDesign"
                          label="Foundation/Design"
                          fullWidth
                          multiline
                          value={values?.foundationDesign}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid>
                      <Grid size={{ xs: 12 }}>
                        <TextField
                          name="additionalNotes"
                          label="Additional Notes"
                          fullWidth
                          multiline
                          value={values?.additionalNotes}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={2} sx={{ mt: 2 }}>
                      <Grid size={{ xs: 12, sm: 4 }}>
                        <TextField
                          name="special"
                          label="Special"
                          select
                          fullWidth
                          value={values?.special}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <MenuItem value="No">No</MenuItem>
                          <MenuItem value="Yes">Yes</MenuItem>
                        </TextField>
                      </Grid>
                      {values?.special === "Yes" && (
                        <Grid size={{ xs: 12, sm: 4 }}>
                          <TextField
                            name="transferSpecial"
                            label="Transfer Special?"
                            select
                            fullWidth
                            value={values?.transferSpecial}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          >
                            <MenuItem value="No">No</MenuItem>
                            <MenuItem value="Yes">Yes</MenuItem>
                          </TextField>
                        </Grid>
                      )}
                      {values?.special === "Yes" && (
                        <Grid size={{ xs: 12 }}>
                          <TextField
                            name="reasonForSpecial"
                            label="Reason For Special"
                            fullWidth
                            multiline
                            value={values?.reasonForSpecial}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Grid>
                      )}
                      <Grid size={{ xs: 12, sm: 4 }}>
                        <TextField
                          name="cornerLot"
                          label="Corner Lot"
                          select
                          fullWidth
                          value={values?.cornerLot}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <MenuItem value="No">No</MenuItem>
                          <MenuItem value="Yes">Yes</MenuItem>
                        </TextField>
                      </Grid>
                      <Grid size={{ xs: 12, sm: 4 }}>
                        <TextField
                          name="waterInjection"
                          label="Water Injection"
                          select
                          fullWidth
                          value={values?.waterInjection}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <MenuItem value="No">No</MenuItem>
                          <MenuItem value="Yes">Yes</MenuItem>
                        </TextField>
                      </Grid>
                      <Grid size={{ xs: 12, sm: 4 }}>
                        <TextField
                          name="piers"
                          label="Piers"
                          select
                          fullWidth
                          value={values?.piers}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <MenuItem value="No">No</MenuItem>
                          <MenuItem value="Yes">Yes</MenuItem>
                        </TextField>
                      </Grid>
                      <Grid size={{ xs: 12, sm: 4 }}>
                        <TextField
                          name="moistureConditioning"
                          label="Moisture Conditioning"
                          select
                          fullWidth
                          value={values?.moistureConditioning}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <MenuItem value="No">No</MenuItem>
                          <MenuItem value="Yes">Yes</MenuItem>
                        </TextField>
                      </Grid>
                      {values?.moistureConditioning === "Yes" && (
                        <Grid size={{ xs: 12, sm: 4 }}>
                          <TextField
                            name="moistureConditioningFt"
                            label="Moisture Conditioning Ft"
                            fullWidth
                            value={values?.moistureConditioningFt}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Grid>
                      )}
                      <Grid size={{ xs: 12, sm: 4 }}>
                        <TextField
                          name="backsToMainStreet"
                          label="Backs To Main Street"
                          select
                          fullWidth
                          value={values?.backsToMainStreet}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <MenuItem value="No">No</MenuItem>
                          <MenuItem value="Yes">Yes</MenuItem>
                        </TextField>
                      </Grid>
                    </Grid>

                    <Grid container spacing={2} sx={{ mt: 2 }}>
                      <Grid size={{ xs: 12, sm: 4 }}>
                        <TextField
                          name="brick1"
                          label="Brick #1"
                          fullWidth
                          value={values?.brick1}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid>
                      <Grid size={{ xs: 12, sm: 4 }}>
                        <TextField
                          name="brick2"
                          label="Brick #2"
                          fullWidth
                          value={values?.brick2}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </DialogContent>
                <DialogActions>
                  <Stack
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                    direction="row"
                    spacing={1}
                  >
                    <Button
                      onClick={() => {
                        setOpen(false);
                      }}
                      variant="outlined"
                      color="primary"
                    >
                      Close
                    </Button>

                    {!startMemoId && createStartMemoAccess && (
                      <LoadingButton
                        type="submit"
                        loading={loadingSubmit}
                        disabled={loadingSubmit || error}
                        sx={{
                          maxWidth: "10rem",
                        }}
                        variant="contained"
                        color="primary"
                      >
                        Create
                      </LoadingButton>
                    )}

                    {startMemoId !== undefined && updateStartMemoAccess && (
                      <LoadingButton
                        type="submit"
                        loading={loadingSubmit}
                        disabled={loadingSubmit || error}
                        sx={{
                          maxWidth: "10rem",
                        }}
                        variant="contained"
                        color="primary"
                      >
                        Update
                      </LoadingButton>
                    )}
                  </Stack>
                </DialogActions>
              </Form>
            )}
          </Formik>
        }
      />
    </>
  );
};

export default CreateStartMemo;
